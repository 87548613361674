'use client'

import Error from 'next/error'
import { captureException } from '@sentry/nextjs'
import { useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function GlobalError({ error }: { error: any }) {
  useEffect(() => {
    captureException(error)
  }, [error])

  return (
    <html>
      <body>
        <Error error={error} statusCode={200} />
      </body>
    </html>
  )
}
